import * as React from "react"
import styled from "styled-components"

import { Box, Grid, Image, ResponsiveContext } from "grommet"
import Container from "./container"

const StyledGrid = styled(Grid)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
`

const StyledImage = styled(Image)`
  object-fit: cover;
  object-position: center center;
  height: 100%;
  max-height: ${({ size }) => (size.includes("small") ? "40vh" : "70vh")};
`

const small = {
  rows: ["1fr"],
}

const large = {
  columns: ["1fr", "1.5fr"],
  rows: ["1fr"],
  gap: "medium",
}

const grid = {
  xsmall: small,
  small: small,
  medium: large,
  large: large,
  xlarge: large,
  xxlarge: large,
}

const ImageContent = ({ children, src, alt }) => {
  const size = React.useContext(ResponsiveContext)

  return (
    <StyledGrid {...grid[size]}>
      <Box>
        <StyledImage size={size} cover alt={alt} src={src} />
      </Box>
      <Box align="center" justify="center">
        <Container>{children}</Container>
      </Box>
    </StyledGrid>
  )
}

export default ImageContent
