import * as React from "react"

import styled from "styled-components"

import StyledImageWrapper from "../components/styled_image_wrapper"
import StyledImage from "../components/styled_image"

import { Box, Grid, ResponsiveContext } from "grommet"

const LeftBox = styled(Box)`
  -webkit-clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 92% 100%, 0 100%);
  background: white url(/bg.svg);
  margin-right: -50px;
`
const RightBox = styled(Box)`
  overflow: visible;
`

const StyledGrid = styled(Grid)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
`

const small = {
  areas: [["right"], ["left"]],
}

const large = {
  columns: ["1.5fr", "1.5fr"],
  rows: ["1fr"],
  areas: [["left", "right"]],
}

const grid = {
  xsmall: small,
  small: small,
  medium: large,
  large: large,
  xlarge: large,
  xxlarge: large,
}

const ContentWrapper = styled.div`
  padding: 2rem;
`

const Hero = ({ left, src }) => {
  const size = React.useContext(ResponsiveContext)

  return (
    <StyledGrid {...grid[size]}>
      <LeftBox gridArea="left" align="center" justify="center">
        <ContentWrapper>{left}</ContentWrapper>
      </LeftBox>
      <RightBox gridArea="right">
        <StyledImageWrapper
          style={{ zIndex: -1, marginLeft: "-8%" }}
          pageSize={size}
        >
          <StyledImage fill src={src} alt="Ecomate App"></StyledImage>
        </StyledImageWrapper>
      </RightBox>
    </StyledGrid>
  )
}

export default Hero
