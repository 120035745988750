import * as React from "react"
import styled from "styled-components"
import Container from "./container"
import { Box, Text, Image, ResponsiveContext, Paragraph } from "grommet"

const ImageBox = styled(Box)`
  position: relative;
`

const ImageLabel = styled(Text)`
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
`
const vissible = {
  xsmall: false,
  small: false,
  medium: false,
  large: true,
  xlarge: true,
}

const Gallery = ({ data }) => {
  const size = React.useContext(ResponsiveContext)

  return vissible[size] ? (
    <Box margin={{ top: "large" }} direction="row" fill="horizontal" wrap>
      {data.map(({ text, src }) => (
        <ImageBox flex="grow" key={src}>
          <Image fit="cover" alt={text} src={src} />
          <ImageLabel color="white">{text}</ImageLabel>
        </ImageBox>
      ))}
    </Box>
  ) : (
    <Container margin={{ bottom: "large" }}>
      <Paragraph>{data.map(r => r.text).join(", ")}</Paragraph>
    </Container>
  )
}

export default Gallery
