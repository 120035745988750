import * as React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/linkbutton"
import Container from "../components/container"
import StripGallery from "../components/strip"
import Hero from "../components/hero"
import ImageContent from "../components/image_with_text"

import Approved from "../images/approved.svg"

import fpData from "../data/frontpage.yml"
import { Box, Heading, Paragraph, Image } from "grommet"

const AngledBox = styled(Box)`
  position: relative;
  &:before {
    content: "";
    height: 50px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    top: 0;

    clip-path: polygon(100% 100%, 0 0%, 50% 0, 100% 0);
    background: var(--light-gray);
  }
`

const CenteredApprovedIcon = styled(Approved)`
  display: inline-block;
  margin-bottom: 0.5rem;
  height: 48px;
  width: 48px;
`

const StyledIcon = styled(Image)`
  margin-left: -2.8em;
  height: 2em;
`

const vissible = {
  xsmall: "none",
  small: "none",
  medium: "flex",
  large: "flex",
  xlarge: "flex",
}

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero
      src="fp.webp"
      left={
        <Box style={{ marginLeft: "2em" }}>
          <Box direction="row" gap="small" align="baseline">
            <StyledIcon alt={""} src="favicon-32x32.png" />
            <Heading style={{ maxWidth: "600px" }}>
              Shared digital platform for on-board to off-shore monitoring
            </Heading>
          </Box>
          <Paragraph style={{ maxWidth: "400px" }}>
            Monitor, report and manage vessel operations with real-time decision
            support
          </Paragraph>
          <Paragraph margin={{ top: "large" }}>
            <Button to="https://app.ecomate.cloud/login" target="_blank">
              Log in
            </Button>
          </Paragraph>
        </Box>
      }
    />
    <Box background="accent-4">
      <Container margin={{ top: "large", bottom: "large" }}>
        <Heading
          level="3"
          textAlign="center"
          alignSelf="center"
          style={{ maxWidth: "400px" }}
        >
          <CenteredApprovedIcon /> <br />
          EcoMATE™ is now ready with a Carbon Intensity Indicator (CII)
        </Heading>
        <Paragraph
          className="body"
          textAlign="center"
          alignSelf="center"
          color="brand"
          style={{ maxWidth: "500px" }}
        >
          Ship operators and managers can now readily quantify, comply and
          report on carbon emissions from ongoing operations
        </Paragraph>
        <div style={{ marginTop: "10px", alignSelf: "center" }}>
          <Button to="/cii_rating">Learn more here</Button>{" "}
        </div>
      </Container>
    </Box>
    <AngledBox>
      <Container margin={{ top: "large" }}>
        <Heading level="2">The Marine centre of excellence.</Heading>
        <Paragraph>
          With 60 years of experience in the marine industry, we offer complete
          in-house expertise. Providing our clients with sustainable and
          future-oriented operations and technology.
        </Paragraph>
      </Container>
    </AngledBox>

    <StripGallery
      display={vissible["small"]}
      data={fpData.excellence_gallery}
    ></StripGallery>

    <ImageContent alt="Map of Norway, with a KROHNE Marine" src="map.png">
      <Box
        align="left"
        margin={{
          top: "small",
          bottom: "large",
        }}
      >
        <Heading level="2">Learn more about KROHNE Marine</Heading>
        <Paragraph>
          KROHNE Marine offers a broad portfolio of products, services and
          solutions for all types of ship
        </Paragraph>
        <Paragraph>
          <Button to="https://krohne-marine.com/" target="_blank">
            Find related information
          </Button>
        </Paragraph>
      </Box>
    </ImageContent>
  </Layout>
)

export default IndexPage
